export interface CosmicIconMediaType {
  bucket: string
  created: string
  folder: string
  id: string
  imgix_url: string
  location: string
  name: string
  original_name: string
  size: number
  type: string
  url: string
}

export enum CloudImages {
  LOGO_WHITE = "https://imgix.cosmicjs.com/26e2c400-75e9-11ef-a74c-ed4eac395353-Platform-Logo.svg",
  MARKETING_LOGO_WHITE = "https://imgix.cosmicjs.com/7a396cb0-9ad5-11ef-b5a0-93db72e2be98-Integral-Logo-White.svg",
  MARKETING_LOGO_BLACK = "https://imgix.cosmicjs.com/61e95e40-9b8e-11ef-b5a0-93db72e2be98-Integral-Logo-Black.svg",
  ICOSAHEDRON_BLACK = "https://imgix.cosmicjs.com/f2b900c0-75d1-11ef-a74c-ed4eac395353-Black-Symbol.svg",
  ICOSAHEDRON_WHITE = "https://imgix.cosmicjs.com/6ff42d30-75d2-11ef-a74c-ed4eac395353-White-Symbol.svg",
  LOGIN_ILLUSTRATION = "https://imgix.cosmicjs.com/866786e0-b2e5-11ed-8bd4-17d132057cff-loginillustration.webp",
  CLOSING_CLIENTS = "https://imgix.cosmicjs.com/0dd1b4b0-b2e7-11ed-8bd4-17d132057cff-IMG1265.webp",
  FUNDRAISING_1 = "https://imgix.cosmicjs.com/26bb1de0-b2e7-11ed-8bd4-17d132057cff-IMG1068.webp",
  ONSITE_1 = "https://imgix.cosmicjs.com/5d7694d0-ce63-11ed-94ed-95257c20dd73-onsite2.webp?crop=faces&w=536&h=402&fit=crop",
  ONSITE_2 = "https://imgix.cosmicjs.com/5d750e30-ce63-11ed-94ed-95257c20dd73-onsite1.webp",
  HIPAA_READY = "https://imgix.cosmicjs.com/c123f770-b2e8-11ed-8bd4-17d132057cff-users.svg",
  FLEXIBLE_COMPLIANCE = "https://imgix.cosmicjs.com/c1246ca0-b2e8-11ed-8bd4-17d132057cff-schema.svg",
  AUDIT_MANAGEMENT = "https://imgix.cosmicjs.com/c122bef0-b2e8-11ed-8bd4-17d132057cff-audit.svg",
  TESTIMONIAL_STARS = "https://imgix.cosmicjs.com/9aaa4a30-c43b-11ee-a799-ff291b5cb9b0-testimonial_stars.svg",
  INTEGRAL_X_SYNTHETIC_DATA = "https://imgix.cosmicjs.com/525eeda0-b48f-11ee-9be1-85f53db06a1d-IntegralXSyntheticData.png",
  INTEGRAL_X_SYNTHETIC_DATA_WITH_LOGO = "https://cdn.cosmicjs.com/a2b688f0-fb6e-11ee-9e48-85dc2e02a5a2-synthetic_with_sim_logo2.png",
  PODCAST_ICON = "https://cdn.cosmicjs.com/d0872120-b179-11ee-9be1-85f53db06a1d-PodcastIcon.svg",
  FINTHRIVE_PODCAST = "https://imgix.cosmicjs.com/3d0eb0c0-b48f-11ee-9be1-85f53db06a1d-FinThrivePodcast.png",
  COMBINED_DATASET = "https://imgix.cosmicjs.com/58f0cfd0-75d7-11ef-a74c-ed4eac395353-b00b2bc0-fb58-11ee-9e48-85dc2e02a5a2-IMAGE_How-It-Works-Hero-Image.png",
  WHY_INTEGRAL = "https://imgix.cosmicjs.com/1c83ddb0-b635-11ee-9be1-85f53db06a1d-WhyIntegral.png",
  TICK_ICON = "https://imgix.cosmicjs.com/1ddaf1f0-b624-11ee-9be1-85f53db06a1d-TickIcon.svg",
  PRIMARY_VIDEO_BUTTON = "https://imgix.cosmicjs.com/38de3e20-c11f-11ee-b518-c90dd1b1cdea-PrimaryVideoButton.png",
  HOVER_VIDEO_BUTTON = "https://cdn.cosmicjs.com/4d10fd10-c11f-11ee-b518-c90dd1b1cdea-HoverVideoButton.png",
  TWITTER_ICON = "https://imgix.cosmicjs.com/30cc8130-6000-11ef-b5ae-a594bb4a8e67-mdi_twitter.svg",
  LINKEDIN_ICON = "https://imgix.cosmicjs.com/c72812c0-c528-11ee-8294-5bdacc8e0a52-linkedin.svg",
  BOOK_DEMO_CALENDAR = "https://imgix.cosmicjs.com/3ae8b430-db36-11ee-b6bc-0f88b0862d1c-book_demo_calendar_3x.png",
  SHUBH_HEADSHOT = "https://imgix.cosmicjs.com/7f4bb3d0-66dc-11ee-83c9-3763ed11a5d2-ShubhProfileHighRes.png",
  ACCEPTED_ICON = "https://imgix.cosmicjs.com/42337bd0-f369-11ee-b555-0d0678c27dd7-carbon_checkmark-filled.svg",
  ISSUE_ICON = "https://imgix.cosmicjs.com/cdfc9d60-4f74-11ef-b1ea-f56c65dfade9-alert.svg",
  STEP_1_VISUAL = "https://imgix.cosmicjs.com/cad0cb30-a900-11ef-bee4-3bb1d3c55332-Updated-Step-1-Visual.png",
  STEP_2_VISUAL = "https://imgix.cosmicjs.com/02daeea0-fb4d-11ee-bec5-7f9403a32f29-Step-2-Visual.png",
  STEP_3_VISUAL = "https://imgix.cosmicjs.com/29c153c0-357b-11ef-a504-63e081e4680f-data_enhancements.png",
  STEP_4_VISUAL = "https://imgix.cosmicjs.com/01315d50-fb4d-11ee-bec5-7f9403a32f29-Step-3-Visual.png",
  STEP_5_VISUAL = "https://imgix.cosmicjs.com/8eae6c30-fb5d-11ee-bec5-7f9403a32f29-Step-4-Visual_updated.png",
  STEP_6_VISUAL = "https://imgix.cosmicjs.com/0dc27c10-75d8-11ef-a74c-ed4eac395353-Step-5-Visual.png",
  TRUSTED_BY_PHARMA_ICON = "https://imgix.cosmicjs.com/5e7eeb70-2da3-11ef-adb1-8b946b3a80e4-trusted_by_pharma.png",
  TRUSTED_BY_ANALYTICS_ICON = "https://imgix.cosmicjs.com/d57dc290-2da4-11ef-adb1-8b946b3a80e4-trusted_by_analytics.png",
  TRUSTED_BY_LLM_ICON = "https://imgix.cosmicjs.com/2b00a420-2f31-11ef-adb1-8b946b3a80e4-trusted_by_llm_2.png",
  TRUSTED_BY_DATA_AGGREGATION_ICON = "https://imgix.cosmicjs.com/d57f2220-2da4-11ef-adb1-8b946b3a80e4-trusted_by_data_aggregation.png",
  HERO_ELLIPSE_DECORATION = "https://imgix.cosmicjs.com/3bc50350-2d9c-11ef-adb1-8b946b3a80e4-hero_background_ellipse.png",
  HERO_CERTIFICATION_COMPLETE_VISUAL = "https://imgix.cosmicjs.com/3d9faae0-75d6-11ef-a74c-ed4eac395353-Certification-Complete-Visual.png",
  ENABLING_COLLABORATION_DECORATIVE_RECTANGLE = "https://imgix.cosmicjs.com/3983b750-2dc7-11ef-adb1-8b946b3a80e4-enabling_collaboration_decorative_rectangle.png",
  ENABLING_COLLABORATION_RECORDS_UNDER_MANAGEMENT_ICON = "https://imgix.cosmicjs.com/b7a36570-2dc9-11ef-adb1-8b946b3a80e4-records_under_management.png",
  ENABLING_COLLABORATION_REDUCTION_IN_TIME_ICON = "https://imgix.cosmicjs.com/b7a5af60-2dc9-11ef-adb1-8b946b3a80e4-reduction_in_time.png",
  INTEGRAL_LAUNCH_VIDEO = "https://cdn.cosmicjs.com/7c1c9f40-914b-11ef-b5a0-93db72e2be98-Integral-Launch-Video_Final_Logo2024_Update_FINAL.mp4",
  FRAGMENTED_DATA_DIAGRAM = "https://imgix.cosmicjs.com/e299dc00-75d6-11ef-a74c-ed4eac395353-lifecycle-visual.png",
  DEFAULT_CASE_STUDY_OG_THUMBNAIL = "https://imgix.cosmicjs.com/ddf5aaa0-5e64-11ef-b5ae-a594bb4a8e67-Press-Release-Cover-1.png",
}

export enum InvestorLogos {
  GENERAL_PARTNERSHIP = "https://imgix.cosmicjs.com/a5a8f770-b571-11ee-9be1-85f53db06a1d-TheGeneralPartnershipLogo.png",
  HAYSTACK = "https://imgix.cosmicjs.com/f70536b0-b571-11ee-9be1-85f53db06a1d-HaystackLogo.png",
  VIRTUE = "https://imgix.cosmicjs.com/f6f81750-b571-11ee-9be1-85f53db06a1d-VirtueLogo.png",
  GREATPOINT = "https://imgix.cosmicjs.com/628497a0-b572-11ee-9be1-85f53db06a1d-GreatpointLogo.png",
  CAFFEINATED_CAPITAL = "https://imgix.cosmicjs.com/62606dd0-b572-11ee-9be1-85f53db06a1d-CaffeinatedCapitalLogo.png",
  VENREX = "https://imgix.cosmicjs.com/627f8e90-b572-11ee-9be1-85f53db06a1d-VenrexLogo.png",
  ALSO_CAPITAL = "https://imgix.cosmicjs.com/62746b00-b572-11ee-9be1-85f53db06a1d-AlsoCapitalLogo.png",
  LIVERAMP = "https://imgix.cosmicjs.com/6273f5d0-b572-11ee-9be1-85f53db06a1d-LiverampLogo.png",
  ARRAY_VC = "https://imgix.cosmicjs.com/62831100-b572-11ee-9be1-85f53db06a1d-ArrayVCLogo.png",
}

export enum MailerAssets {
  DONE_ICON = "https://cdn.cosmicjs.com/920e82a0-01a9-11ef-bec5-7f9403a32f29-mailersend_milestone_done.png",
  IN_PROGRESS_ICON = "https://cdn.cosmicjs.com/65b14f80-00be-11ef-bec5-7f9403a32f29-mailersend_milestone_in_progress.png",
  NOT_STARTED_ICON = "https://cdn.cosmicjs.com/65b39970-00be-11ef-bec5-7f9403a32f29-mailersend_milestone_not_started.png",
}

export enum DataSourceConnector {
  AZURE_BLOB_STORAGE = "https://imgix.cosmicjs.com/65bc1af0-283c-11ef-adb1-8b946b3a80e4-azure.svg",
  S3 = "https://imgix.cosmicjs.com/18473290-283d-11ef-adb1-8b946b3a80e4-s3.svg",
  GCS = "https://imgix.cosmicjs.com/134ec460-283d-11ef-adb1-8b946b3a80e4-gcs.svg",
  SFTP = "https://imgix.cosmicjs.com/0d558800-283d-11ef-adb1-8b946b3a80e4-sftp.svg",
  SNOWFLAKE = "https://imgix.cosmicjs.com/0d562440-283d-11ef-adb1-8b946b3a80e4-snowflake.svg",
  BIG_QUERY = "https://imgix.cosmicjs.com/c9a29940-283c-11ef-adb1-8b946b3a80e4-bigquery.svg",
}

export const defaultImage = "https://imgix.cosmicjs.com/62a4fce0-f40f-11ed-bb44-790a83f99a24-default.png"
