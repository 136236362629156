import { useState, useRef } from "react"

import { useDisclosure } from "@chakra-ui/react"
import Link from "next/link"
import { Flex, Box, Hide, Menu, MenuButton, IconButton, MenuList, Button, Text, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from "@chakra-ui/react"
import { FiMenu, FiChevronDown } from "react-icons/fi"
import { useRouter } from "next/router"
import Logo, { LogoVariant } from "src/components/Website/Logo"
import { NavbarTheme } from "src/layouts/WebsiteLayout"

interface LinkItemProps {
  name: string
  route?: string
  children?: Array<LinkItemProps>
}

const DesktopLinkItems: Array<LinkItemProps> = [
  { name: "How It Works", route: "/how-it-works" },
  {
    name: "Integral For",
    children: [
      { name: "Advertising", route: "https://why.useintegral.com/advertising" },
      { name: "Data Purchasing", route: "https://why.useintegral.com/data-purchasing" },
      { name: "Data Collaboration", route: "https://why.useintegral.com/data-collaboration" },
      { name: "Clean Rooms", route: "https://why.useintegral.com/clean-rooms" },
      { name: "Health Data Monetization", route: "https://why.useintegral.com/health-data-monetization" },
      { name: "Healthcare Marketing", route: "https://why.useintegral.com/healthcare-marketing" },
      { name: "Mortality Research", route: "https://why.useintegral.com/mortality-research" },
      { name: "Data Infrastructure", route: "https://why.useintegral.com/data-infrastructure" },
    ],
  },
  { name: "Why Us", route: "/why-us" },
  { name: "Use Cases", route: "/customer-stories" },
  { name: "Blog", route: "/blog" },
]

const MobileLinkItems: Array<LinkItemProps> = [
  { name: "How It Works", route: "/how-it-works" },
  {
    name: "Integral For",
    children: [
      { name: "Advertising", route: "https://why.useintegral.com/advertising" },
      { name: "Data Purchasing", route: "https://why.useintegral.com/data-purchasing" },
      { name: "Data Collaboration", route: "https://why.useintegral.com/data-collaboration" },
      { name: "Clean Rooms", route: "https://why.useintegral.com/clean-rooms" },
      { name: "Health Data Monetization", route: "https://why.useintegral.com/health-data-monetization" },
      { name: "Healthcare Marketing", route: "https://why.useintegral.com/healthcare-marketing" },
      { name: "Mortality Research", route: "https://why.useintegral.com/mortality-research" },
      { name: "Data Infrastructure", route: "https://why.useintegral.com/data-infrastructure" },
    ],
  },
  { name: "Why Us", route: "/why-us" },
  { name: "Use Cases", route: "/customer-stories" },
  { name: "Blog", route: "/blog" },
  { name: "Contact Us", route: "/contact-us" },
]

const NavItem = ({ route, children, ...props }: { route: string; children }) => {
  return (
    <Link href={route}>
      <Flex
        align="center"
        h={["24px", "48px"]}
        p={["0px", "44px 24px"]}
        borderRadius="lg"
        role="group"
        cursor="pointer"
        color="#9296AD"
        fontSize="18px"
        _hover={{
          textDecoration: "underline",
        }}
        {...props}
      >
        {children}
      </Flex>
    </Link>
  )
}

const HoverMenu = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false)
  const menuRef = useRef<HTMLDivElement | null>(null)

  return (
    <Box zIndex={999} ref={menuRef} onMouseEnter={() => setIsOpen(true)} onMouseLeave={() => setIsOpen(false)} position="relative">
      <Flex
        align="center"
        h="48px"
        p="44px 24px"
        borderRadius="lg"
        cursor="pointer"
        color="#9296AD"
        fontSize="18px"
        _hover={{
          textDecoration: "underline",
        }}
      >
        <Text color="#9296AD">Integral For</Text>
        <FiChevronDown
          style={{
            marginLeft: "4px",
            transform: isOpen ? "rotate(180deg)" : "rotate(0)",
            transition: "transform 0.2s",
          }}
        />
      </Flex>
      {isOpen && (
        <Box position="absolute" top="100%" left={0} zIndex={1} bg="white" boxShadow="md" borderRadius="md" p={2} minWidth="224px">
          {children}
        </Box>
      )}
    </Box>
  )
}

const Navbar = ({ theme }: { theme: NavbarTheme }) => {
  const router = useRouter()
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <Box width="100%" backgroundColor={theme === NavbarTheme.Dark ? "var(--website-color-background-base)" : "none"}>
      <Flex justifyContent="space-between" px={["40px", 0, 0]} py={["8px", 0, 0]} maxW={["inherit", "inherit", "var(--website-width-max)"]} m={["inherit", "inherit", "0 auto"]}>
        <Flex align="center">
          <Logo variant={theme === NavbarTheme.Dark ? LogoVariant.White : LogoVariant.Black} context="navbar" />
        </Flex>

        <Flex alignItems="center">
          <Box display={{ base: "flex", md: "none" }}>
            <Menu isOpen={isOpen} onClose={onClose}>
              <MenuButton as={IconButton} aria-label="Options" icon={<FiMenu color={theme === NavbarTheme.Dark ? "white" : "black"} />} size="lg" colorScheme="navy" minWidth="none" onClick={onOpen} />
              <MenuList padding={0} minWidth="240px">
                <Accordion allowToggle width="100%">
                  {MobileLinkItems.map((link) => {
                    if (link.children) {
                      return (
                        <AccordionItem key={link.name} border="none">
                          <AccordionButton as={Flex} justifyContent="space-between" alignItems="center" px={3} py={2} cursor="pointer" _expanded={{ bg: "#E2EAF0" }} _hover={{ bg: "#E2EAF0" }}>
                            <Text>{link.name}</Text>
                            <AccordionIcon />
                          </AccordionButton>
                          <AccordionPanel pb={2} px={0} bg="#E2EAF0">
                            {link.children.map((child) => (
                              <Box
                                key={child.name}
                                as="a"
                                href={child.route}
                                display="block"
                                py={2}
                                px={3}
                                bg="#E2EAF0"
                                _hover={{
                                  bg: "#d1dbe3",
                                  textDecoration: "underline",
                                }}
                              >
                                {child.name}
                              </Box>
                            ))}
                          </AccordionPanel>
                        </AccordionItem>
                      )
                    }
                    return (
                      <Box key={link.name} as="a" href={link.route} display="block" py={2} px={3} _hover={{ bg: "#E2EAF0" }}>
                        {link.name}
                      </Box>
                    )
                  })}
                </Accordion>
              </MenuList>
            </Menu>
          </Box>

          <Box display={{ base: "none", md: "flex" }} marginRight="16px">
            {DesktopLinkItems.map((link) => {
              if (link.children) {
                return (
                  <HoverMenu key={link.name}>
                    {link.children.map((item) => (
                      <Link key={item.name} href={item.route || ""}>
                        <Box p={2} _hover={{ bg: "#F7F5FF" }} borderRadius="4px" fontSize="16px">
                          {item.name}
                        </Box>
                      </Link>
                    ))}
                  </HoverMenu>
                )
              }
              return (
                <NavItem key={link.name} route={link.route || ""}>
                  {link.name}
                </NavItem>
              )
            })}
          </Box>

          <Hide below="md">
            <Button
              color="white"
              bg="var(--website-color-highlight)"
              borderRadius="50px"
              _hover={{
                bg: "var(--website-color-highlight)",
              }}
              onClick={() => router.push("/contact-us")}
              fontSize="18px"
            >
              Contact Us
            </Button>
          </Hide>
        </Flex>
      </Flex>
    </Box>
  )
}

export default Navbar
